"use client";
import { XIcon } from "lucide-react";
import {
  Button, Progress, DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "ui";
import { LanguageSwitcher, LanguageSwitcherProps } from "./LanguageSwitcher";
import ReactCountryFlag from "react-country-flag";
export interface LanguageInputSelectoreProps {
  languages: {
    code: string;
    name: string;
    flagCode: string;
    id: string;
  }[];
  selectedLanguages: string[];
  onChange: (languages: string[]) => void;
}

export const LanguageInputSelectore = ({
  languages,
  selectedLanguages,
  onChange,
}: LanguageInputSelectoreProps) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row gap-2">
        {languages.filter((lang) => selectedLanguages.find((ele) => ele === lang.id)).map((lang) => (
          <div
            key={lang.id}
            className="flex  h-7 w-fit items-center space-x-1 rounded-full border border-green-200 bg-green-50 py-[2px] pe-1 ps-2"
          >
            <ReactCountryFlag
              countryCode={lang.flagCode}
              svg
              style={{ width: "16px",height: "16px"}}
              title={lang.name}
            />
            <span className="text-[#067647] font-medium">{lang.name}</span>

            <Button
              className="!p-0"
              onClick={() => {
                selectedLanguages.length != 1 && onChange(selectedLanguages.filter((ele) => ele !== lang.id));
              }}
            >
              {(selectedLanguages.length != 1 &&
                <XIcon className="mx-2 h-[18px] w-[18px] text-gray-500"/>)}
            </Button>
          </div>
        ))}
      </div>

      {(selectedLanguages.length == 1 &&

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <span className="text-sm mt-4 cursor-pointer text-[#7F56D9] underline">+ Add another Language</span>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start" className="">
            {languages.filter((lang) => selectedLanguages.find((ele) => ele !== lang.id)).map((item, index) => (
              <DropdownMenuItem onClick={() => onChange([...selectedLanguages,item.id])} key={index}>
                {item.name}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      )}




    </div>
  );
};
