"use client";
import { Languages, LoaderCircle, CircleHelp } from "lucide-react";
import { Button, Progress } from "ui";
import { LanguageSwitcher, LanguageSwitcherProps } from "./LanguageSwitcher";
import ReactCountryFlag from "react-country-flag";

export interface FormTranslaterProps extends LanguageSwitcherProps {
  onSubmit?: () => void;
  onCancel: () => void;
  submitText: string;
  cancelText?: string;
  isLoading?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
}

export const FormTranslater = ({
  onSubmit,
  onCancel,
  submitText,
  languages,
  currentLanguage,
  switchLanguage,
  label,
  cancelText,
  isLoading,
  type,
}: FormTranslaterProps) => {
  return (
    <div className="flex w-full items-center justify-between   gap-6 divide-x rounded-lg border bg-white p-6 shadow-xl rtl:space-x-reverse">
      <div className="flex items-center gap-4">
        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#f9fafb]/80">
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-[#F2F4F7]">
            <Languages className="h-5 w-5 text-[#475467]" />
          </div>
        </div>
        <div className="flex flex-col">
          <div className=" text-xl font-semibold text-gray-950">
            Translation helper
          </div>
          <div className="text-primary flex items-center  gap-1">
            <CircleHelp className="h-4 w-4 " />
            <span className="text-xs ">How it works?</span>
          </div>
        </div>
      </div>
      <div className="  flex-1 ps-2">
        <LanguageSwitcher
          languages={languages}
          currentLanguage={currentLanguage}
          switchLanguage={switchLanguage}
          label={label}
        />
      </div>
      <div className="flex flex-auto  flex-col gap-2 px-2">
        {languages.map((lang) => {
          return (
            <div key={lang.code} className="flex w-full items-center space-x-2">
              <ReactCountryFlag
                countryCode={lang.flagCode}
                svg
                style={{ width: "18px",height: "18px"}}
                title={lang.name}
              />
              <span>{lang.name}</span>
              <Progress
                value={
                  ((lang.filledFields || 0) / (lang.fieldsNumber || 1)) * 100
                }
                className="!h-[3px] !w-full"
              />
              <span className="whitespace-nowrap text-xs text-[#344054]">
                {Math.ceil(
                  ((lang.filledFields || 0) / (lang.fieldsNumber || 1)) * 100
                )}
                % Complete
              </span>
            </div>
          );
        })}
      </div>
      <div className="flex flex-col gap-2 px-2">
        <Button
          type={type}
          onClick={onSubmit}
          className="!bg-primary text-white "
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="flex items-center space-x-2">
              <LoaderCircle className="h-5 w-5 animate-spin" />
              <span>{submitText}...</span>
            </div>
          ) : (
            submitText
          )}
        </Button>
        <Button onClick={onCancel} className="border  ">
          {cancelText || "Cancel"}
        </Button>
      </div>
    </div>
  );
};
