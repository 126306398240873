"use client";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import { switchLocaleAction } from "../fetchers/switch-locale";
import { ChevronDown } from "lucide-react";

export interface LanguageSwitcherProps {
  languages: {
    code: string;
    name: string;
    flagCode: string;
    fieldsNumber?: number;
    filledFields?: number;
  }[];
  currentLanguage: string;
  switchLanguage?: (language: string) => void;
  label?: string;
}

export function LanguageSwitcher({
  languages,
  currentLanguage,
  switchLanguage,
  label,
}: LanguageSwitcherProps) {
  return (
    <div className="flex w-full flex-col gap-1">
      {label && <label className="text-sm  text-[#344054]">{label}</label>}
      <div className="relative min-w-[208px]">
        <select
          onChange={(e) => {
            switchLanguage
              ? switchLanguage(e.target.value)
              : switchLocaleAction(e.target.value);
          }}
          value={currentLanguage}
          className="lang-switcher !w-full min-w-[208px] rounded-md border-0 border-gray-300 p-0 text-xl  font-semibold"
        >
          {languages.map((lang) => {
            return (
              <option key={lang.code} value={lang.code}>
                {lang.name}
              </option>
            );
          })}
        </select>
        <span className="pointer-events-none absolute top-1/2 -translate-y-1/2 transform  ltr:right-2 rtl:!left-2 rtl:right-auto ">
          <ChevronDown size={24} />
        </span>
      </div>
    </div>
  );
}
